<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader  class="mx-6" />
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table 
                :columns="computedColumns" 
                :isLoading="loading" 
                :rows="rows" 
                :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false,}" 
                @on-column-filter="onColumnFilter" 
                v-bind="config" 
                :totalRows="pageData.total_count"
                @on-page-change="onPageChange($event)"
                @on-per-page-change="onPageChange($event, true)"
            >
                <template #column-filter="{ column, updateFilters }">
                    <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                        <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div>
                    <dropdown
                        :ref="setFilterRef"
                        reposition
                        searchable
                        class="filter-dropdown"
                        placeholder="Select"
                        v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                        :options="column.filterOptions.filterDropdownItems"
                        @input="(value) => updateColumnFilters(column, value, updateFilters)"
                        :value="getFilterValue(column)"
                        @search="searchFilter(column, $event)"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="{ label: 'name', trackBy: 'id' }"
                        :multiple="false"
                        :taggable="false"
                    >
                        <template #caret>
                          <sort-icon class="fill-current" />
                        </template>
                    </dropdown>
                    <div class="" v-else>
                        <!-- // adding a blank div to remove the default template   -->
                    </div>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'unique_id'" class="flex gap-3 pl-3">
                        {{ props.row.unique_id}}
                    </div>
                     <div v-if="props.column.field == 'analyst'" class="flex justify-center">
                        {{props.row.analyst}}
                    </div>
                    <div v-if="props.column.field == 'case_id'" class="flex justify-center">
                        {{props.row.case_id}}
                    </div>
                    <div v-if="props.column.field == 'date'" class="flex justify-center">
                        {{ props.row.date ? FormatDateNew(new Date(props.row.date)) : '-'}}
                    </div>
                    <div v-if="props.column.field == 'credit_used'" class="flex justify-center">
                        {{ props.row.credit_used }}
                    </div>
                    <div v-if="props.column.field == 'source'" class="flex justify-center">
                        {{ props.row.source }}
                    </div>
                    <div v-if="props.column.field == 'service_charge'" class="flex justify-center">
                        {{ props.row.service_charge }}
                    </div>
                    <div v-if="props.column.field == 'query'" class="flex justify-center capitalize">
                        <svg @click="queryinfo(props.row.query)" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="2" width="18" height="18" rx="9" fill="#0D69D5"/>
                            <path d="M9.50539 8.84C9.20539 8.84 8.95872 8.75333 8.76539 8.58C8.57872 8.4 8.48539 8.18 8.48539 7.92C8.48539 7.65333 8.57872 7.43333 8.76539 7.26C8.95872 7.08 9.20539 6.99 9.50539 6.99C9.79872 6.99 10.0387 7.08 10.2254 7.26C10.4187 7.43333 10.5154 7.65333 10.5154 7.92C10.5154 8.18 10.4187 8.4 10.2254 8.58C10.0387 8.75333 9.79872 8.84 9.50539 8.84ZM10.3554 9.42V15H8.64539V9.42H10.3554Z" fill="white"/>
                        </svg>
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <button 
                v-if="isFilters"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
                Clear filters
            </button>
            <Modal ref="Query-info" title='Query' :showFooter='false'> 
                <template #content>
                    <div class="grid gap-2"> 
                        <span class="border-b text-bold" >{{ }}</span>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import {getCreditUsage, getQuery} from "../../services";
    import Loader from "@/components/loader";
    import Modal from "@/components/modal-content";
    import { FormatDateNew } from "@/plugins/functions";
    import DatePicker from "vue2-datepicker";
    import dropdown from "@shared/components/dropdown-base";
    import { onlyUnique } from "@shared/plugins/utils.js";
    import { uuid } from "vue-uuid";
    import { DateTime } from "luxon";
    import { format , parse } from "date-fns";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            DatePicker,
            dropdown,
            Modal,
        },
        data() {
            return {
                columns: [  
                    { 
                        label: "Pack Unique Id", 
                        field: "unique_id",
                        config: {
                          filter: true,
                          alignCenter: true,
                        },
                    },
                    { 
                        label: "Analyst", 
                        field: "analyst",
                        config: {
                          filter: true,
                          alignCenter: true,
                        }, 
                    },
                    { 
                        label: "Date", 
                        field: "date",
                        // config: {
                        //     filter: true,
                        //     alignCenter: true,
                        // },
                    },
                    { 
                        label: "Case Id", 
                        field: "case_id",
                        config: {
                          filter: true,
                          alignCenter: true,
                        },
                    },
                    { 
                        label: "Source", 
                        field: "source",
                        config: {
                          filter: true,
                          alignCenter: true,
                        },
                    },
                    { 
                        label: "Credit Used", 
                        field: "credit_used",
                        config: {
                          filter: true,
                          alignCenter: true,
                        },
                    },
                    // { 
                    //     label: "Service Charge", 
                    //     field: "service_charge",
                    //     filterOptions: {
                    //         enabled: true,
                    //     }, 
                    // }, 
                    // { label: "Query", field: "query" }, 

                ],
                rows: [],
                serverParams: {},
                filterRefs: [],
                filterOptions: {},
                searchLoading:{},
                userLists: [],
                isSelectionChanged: false,
                params: {
                    page: 1,
                    limit: 10,
                },
                pageData: {
                    total_count: 0,
                },
                loading: false,
                query: null,
                
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditPacks()
            this.loading = false

        },
        computed: { 
            isFilters() {
              return this.serverParams && Object.entries(this.serverParams).length;
            },
            columnFilterOptions() {
                return this.getFilterOptions(this.columnOption, this.rows);
            },
            columnOption() {
              return this.columns.map((el) => ({
                ...el,
                config: 
                {
                    ...el.config,
                    isLoading: !!this.searchLoading[el.field] || false,
                },
              }));
            },
            computedColumns() {
              // use option list from server if it is:
              const options = this.columnFilterOptions;
              // had to check for empty object
              if (options && Object.entries(options).length) {
                return this.columns.map((col) => {
                  const filtered = col.config?.filter ?? true;
                  if (col.prefetchedOptions && col.prefetchedOptions.length) {
                    return {
                      ...col,
                      filterOptions: {
                        enabled: filtered,
                        filterDropdownItems: col.prefetchedOptions,
                      },
                    };
                  } else {
                    return {
                      ...col,
                      filterOptions: {
                        enabled: filtered,
                        filterDropdownItems: options[col.field],
                      },
                    };
                  }
                });
              } else {
                // TODO remove/rework default filters
                return this.columns.map((col) => {
                  const filtered = col.config?.filter ?? true;
                  return {
                    ...col,
                    filterOptions: filtered
                      ? {
                        enabled: true,
                        placeholder: "All",
                        filterDropdownItems: this.rows
                          .filter((row) => row[col.field])
                          .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                          .filter(onlyUnique),
                      }
                      : undefined,
                  };
                });
              }
            }
        },
        methods: {
            FormatDateNew,
            async setCreditPacks() {   
                const filterParams = this.makeFilterParams();
                let qParams
                qParams = new URLSearchParams(this.params);
                if (filterParams) {
                    qParams = new URLSearchParams({...this.params, ...filterParams});
                }
                // const qParams = `${this.$route.params.id}&page=${this.params.page}&limit=${this.params.count}`
                const {data} = await getCreditUsage(this.$route.params.id, qParams)
                this.rows = data.credit_packs_usage
                this.pageData = data?.pagination_info;
            },
            getStatusColor(status) {
                if (status.toLowerCase() == "in use") {
                    return "text-yellow-500"
                }
                if (status.toLowerCase() == 'active') {
                    return 'text-green-400'
                }
            },
            async queryinfo(query){
                const {data } = await getQuery(query)
                this.query =  data
                this.$refs["Query-info"].showModal();
            },
            async clearFilters() {
                this.serverParams = null;
                await this.setCreditPacks();
            },
            async onColumnFilter({ columnFilters }) {
                this.params.page = 1;
                this.serverParams = columnFilters ? { ...columnFilters } : null;
                await this.setCreditPacks();
            },
            selectionChanged(e) {
                if (e) {
                    this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                    this.$emit('on-selected-rows-change', e.selectedRows)
                }
            },
            getFilterOptions(columns, row) {
                return columns.reduce((result, curr) => {
                  result[curr.field] = row
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => (
                      { 
                        id: uuid.v4(), name: el 
                      }));
                      if (curr?.field == 'date') {
                        result[curr.field] = result[curr.field]?.map(el => {
                          return {
                            ...el,
                            name: FormatDateNew(new Date(el.name)),
                            label: el.name
                          }
                        })
                      }
                      return result;
                    }, 
                {});
            },
            makeFilterParams() {
                let filterParams = {};
                if (this.serverParams) {
                  Object.keys(this.serverParams).map((key) => {
                    if (key == 'date') {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
                    } else {
                      filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                    }
                    if (this.serverParams[key]["name"] === undefined) {
                      delete filterParams[key];
                    }
                  });
                }
                return filterParams;
            },
            updateColumnFilters(column, value /* , callback */) {
                let filterValObj = {
                  ...this.serverParams,
                  [column.query_key || column.field]: value ? {
                    ...value,
                    field_type: column.field_type,
                  } : null,
                };
                if (!filterValObj[column.query_key || column.field]) {
                  delete filterValObj[column.query_key || column.field];
                }
                if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
                  let date = DateTime.fromISO(value.name).toISODate()
                  if (date == null) {
                    const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                    date = format(parsedDate, 'yyyy-MM-dd');
                    if (filterValObj.from_created_at) {
                      filterValObj.from_created_at.name = date.toString().substring(10, 0)
                    } else {
                      filterValObj.from_due_date.name = date.toString().substring(10, 0)
                    }
                  }
                  Object.assign(filterValObj, {
                    [`to_${column.field}`]: {
                      id: date.ts,
                      name: date.toString().substring(10, 0),
                      field_type: column.field_type
                    },
                  });
                }
                if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
                  let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
                  Object.assign(filterValObj, {
                    [column.config.query_keys['start']]: {
                      id: start.ts,
                      name: value[0] ? start.toString() : null,
                      field_type: column.field_type
                    },
                  })
                  Object.assign(filterValObj, {
                    [column.config.query_keys['end']]: {
                      id: end.ts,
                      name: value[1] ? end.toString() : null,
                      field_type: column.field_type
                    },
                  })
                }
                this.onColumnFilter( {
                  columnFilters: filterValObj,
                });
            },
            async onPageChange(data, resetPage = false) {
                this.params = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.params.page = 1;
                await this.setCreditPacks();
            },
            searchFilter(column, value) {
                this.$emit("search-filter", { column, value });
            },
            setFilterRef(el) {
                if (el) {
                    this.filterRefs.push(el);
                }
            },
            getFilterValue(col) {
                if (this.isFilters) {
                  return this.serverParams[col.field] ?? this.serverParams[col.query_key];
                } else return null;
            },
            dateFilterFn(data, filterString) {
                let givenDate = FormatDateNew(new Date(data)).toLowerCase();
                return givenDate.includes(filterString.toLowerCase());
            },
        }

    }
</script>


